import React from "react";
import Layout from "../layout";
import { Link } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  innerWidth,
  screen,
  navyBlue,
  aku,
  gotham,
  gothamBold,
  mainRed,
} from "../components/common/variables";
import logo from "../images/tenalphas-moving-content.jpg";
import Arrow from "../images/svg/right-arrow.svg";
import { parse } from "search-params";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 45px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  min-height: 300px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
      }
    }

    &--year {
      color: ${mainRed};
    }
  }

  .full-img {
    width: 100%;
    display: block;
    margin-top: 40px;
  }

  .back-link {
    font-family: ${gotham};
    font-size: 1.1rem;
    color: ${mainRed};
    display: table;
    margin-top: 15px;
    @media ${screen.small} {
      margin-top: 30px;
      font-size: 1.25rem;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .description {
    margin-top: 15px;
    @media ${screen.large} {
      display: flex;
    }

    h6 {
      color: ${mainRed};
      font-family: ${gothamBold};
      font-size: 0.95rem;
      margin-right: 7px;
      @media ${screen.small} {
        font-size: 1.15rem;
        margin-right: 10px;
      }
    }

    p {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 0.92rem;
      white-space: pre-line;
      @media ${screen.small} {
        font-size: 1.15rem;
      }
    }
  }
`;

const PreviewPage = () => {
  // content from url
  const content =
    typeof window !== `undefined` && parse(window.location.search);

  return (
    <Layout>
      <SEO
        title={
          content && content.title === "null"
            ? "Preview Full Image"
            : content.title
        }
        image={logo}
      />
      <Wrapper>
        {content && content.title !== "null" && (
          <h2 className="heading">
            <Arrow />
            {content.title} -{" "}
            {content.date && (
              <span className="heading--year">{content.date.slice(0, 4)}</span>
            )}
          </h2>
        )}

        {content.credits && (
          <div className="description">
            <h6>Credits: </h6>
            <p>{content.credits}</p>
          </div>
        )}

        <Link className="back-link" to="/infographics">
          Go Back
        </Link>

        {content && content.img && (
          <img className="full-img" src={content.img} alt="Infographic" />
        )}
      </Wrapper>
    </Layout>
  );
};

export default PreviewPage;
